html {
  background-color: black;
  width:100%;
  height:100%;
  margin:0;
  overflow:hidden;
}

.App {
  text-align: left;
  width: 100%;
}

#video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
}

.content {
  top: 0;
  padding-left: 2%;
  width: 100%;
  height: 100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: left;
  font-size: 13.5vw;
  color: white;
  position: fixed;
  width: 100%;
  height: 100%;
}

.coming-soon {
  font-size: 3.5vw;
  color: white;
  width: 100%;
  height: 100%;
  padding-left: 1%;
}

.footer {
  bottom: 0;
  font-size: 0.7vw;
  color: white;
  width: 100%;
  padding-left: 1%;
  position: fixed;
}
